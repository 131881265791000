<template>
  <div>
    <div class="title">
      <div>店内订单明细</div>
      <div>
        <Button icon="md-download" type="text" class="md-download-btn" @click="exportReport">导出</Button>
      </div>
    </div>
    <div class="tab">
      <base-form ref="baseForm" :formList="formList" @search="search"></base-form>
    </div>
    <div class="table" ref="tableBox">
      <!-- <div class="Tabs">
        <Tabs v-model="tabsValue">
          <TabPane v-for="item in tabsList" :key="item.value" :label="item.label" :name="item.value"></TabPane>
      </Tabs>
      </div> -->
      <IviewTable
        :height="tableHeight"
        :border="true"
        :page="page"
        :columns="tableColumns"
        :data="tableData"
        :context="self"
        backStage
        show-summary
        :summary-method="handleSummary" 
        @on-change="pageChange"
        @on-page-size-change="pageSizeChange"
      ></IviewTable>
      <div class="amount-tips">
        <div>合计订单金额：</div>
        <div>{{ orderAmount }}元</div>
      </div>
    </div>

    <Modal
      v-model="showModal3"
      :title="`${rowData.newOrderCode}-订单详情`"
      width="800"
      footer-hide
    >
      <food-detail :tableData="foodData"></food-detail>
    </Modal>
    
  </div>
</template>

<script>
import { formatTime } from "@/utils";
import IviewTable from '_c/iview/IviewTable'
import baseForm from '@/components/baseForm'
import FoodDetail from './food-detail.vue'
import mixin from '@/mixins'
let toYuan = (h, params, props) => {
  return h(
    'span',
    {
      props: {},
    },
    (params.row[props]/100).toFixed(2)
  )
}
export default {
  name: '',
  components: {
    baseForm,
    IviewTable,
    FoodDetail
  },
  mixins: [mixin],
  data() {
    return {
      self: this,
      showModal3: false,
      modalFormStatus: false,
      tableHeight:0,
      orderAmount: 0,
      formValidate: {
        dataRange: [],
      },
      tabsValue: "0",
      tabsList:[{
        label:"全部订单",
        value:"0"
      },{
        label:"待接单",
        value:"1"
      },{
        label:"待制作",
        value:"2"
      },{
        label:"未结账",
        value:"3"
      },{
        label:"已结账",
        value:"4"
      },{
        label:"已撤单",
        value:"5"
      },{
        label:"已退单",
        value:"6"
      }],
      formList: [
        {
          label: '',
          type: 'datePicker-daterange',
          model: 'dataRange',
        },
      ],
      page: {
        current: 1,
        pageSize: 40,
        total: 0,
      },
      rowData: {},
      tableData: [],
      tableColumns: [
        // {
        //   type: 'selection',
        //   width: 60,
        //   align: 'center'
        // },
        {
          title: '门店名称',
          key: 'poiName',
        },
        {
          title: '订单号',
          key: 'newOrderCode',
          width: '190px'
        },
        {
          title: '下单时间',
          key: 'orderTimeStr',
          width: '160px'
        },
        {
          title: '订单金额(元)',
          key: 'amount',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'amount')
        },
        {
          title: '顾客应付(元)',
          key: 'receivable',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'receivable')
        },
        {
          title: '订单优惠(元)',
          key: 'discount',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'discount')
        },
        {
          title: '订单收入(元)',
          key: 'income',
          width: '100px',
          summary: true,
          render: (h, params) => toYuan(h, params, 'income')
        },
        {
          title: '操作',
          key: 'action',
          fixed: 'right',
          width: 100,
          render: (h, params) => {
            return h('div', [
              h(
                'Button',
                {
                  props: {
                    type: 'primary',
                    size: 'small'
                  },
                  on: {
                    click: () => {
                      this.showOrderDetail(params.row)
                    },
                  },
                },
                '订单详情'
              ),
            ])
          },
        }
      ],
      foodData: []
    }
  },
  mounted() {
    // this.$refs.baseForm.setData(this.formValidate)
    this.getElementHeight();
    // 监听页面rezise事件
    window.addEventListener("resize", this.getElementHeight);
    this.$once("hook:beforeDestroy", () => {
      window.removeEventListener("resize", this.getElementHeight);
    });
  },
  methods: {
    exportReport(){
      if(this.formValidate.start && this.formValidate.end){
        let params = {
          start: this.formValidate.start,
          end: this.formValidate.end
        }
        this.$api.order.export(params).then((res) => {

        })
      }else{
        this.$Message.warning('请先查询要导出的内容');
      }
    },
    getElementHeight() {
      const element = this.$refs.tableBox;
      const height = element.offsetHeight; // 或者使用其他的属性，如clientHeight、scrollHeight等
      console.log('Element height:', height);
      // 其他逻辑处理
      this.tableHeight = height - 90
    },
    handleSummary ({ columns, data }) {
      const sums = {};
      columns.forEach((column, index) => {
        console.log(column)
          const key = column.key;
          if (index === 0) {
              sums[key] = {
                key,
                value: '合计'
              };
              return;
          }
          if (!column.summary) {
              sums[key] = {
                  key,
                  value: ''
              };
              return;
          }
          const values = data.map(item => Number(item[key]));
          if (!values.every(value => isNaN(value)) && values.length>0) {
              const v = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  if (!isNaN(value)) {
                      return prev + curr;
                  } else {
                      return prev;
                  }
              }, 0);
              sums[key] = {
                key,
                value: (v/100).toFixed(2)
              };
          } else {
              sums[key] = {
                  key,
                  value: '0.00'
              };
          }
      });
      return sums;
    },
    pageChange(page){
      this.page.current = page
      this.formValidate.page = page
      this.getOrderList(this.formValidate)
    },
    pageSizeChange(pageSize){
      this.page.current = 1
      this.page.pageSize = pageSize
      this.formValidate.page = 1
      this.formValidate.pageSize = pageSize
      this.getOrderList(this.formValidate)
    },
    search(data,close) {
      this.page.current = 1
      let params = {
        ...data,
        page: this.page.current,
        pageSize: this.page.pageSize,
      }
      if(close){
        this.page.total = 0
        this.tableData = []
        return
      }
      if(this.isAdmin && (!data || !data.orgId)){
        this.$Message.warning('请选择要查询的门店');
      }else if(!data || !data.dataRange || data.dataRange.length!==2 || !data.dataRange[0] || !data.dataRange[1]){
        this.$Message.warning('请选择要查询的日期');
      } else {
        params.start = formatTime(data.dataRange[0])[1]
        params.end = formatTime(data.dataRange[1])[1]
        console.log(params)
        this.formValidate = params
        this.getOrderList(params)
      }
      // if(data && data.dataRange && data.dataRange.length==2 && data.dataRange[0] && data.dataRange[1]){
      //   params.start = formatTime(data.dataRange[0])[1]
      //   params.end = formatTime(data.dataRange[1])[1]
      //   console.log(params)
      //   this.formValidate = params
      //   this.getOrderList(params)
      // } else {
      //   if(close){
      //     this.page.total = 0
      //     this.tableData = []
      //   }else{
      //     this.$Message.warning('请选择要查询的日期');
      //   }
      // }
    },
    getOrderList(params = {}) {
      this.$api.order.getOrderList(params).then((res) => {
        console.log(res)
        this.tableData = res.items.map((item) => {
          return {
            ...item.orderBase,
            orderTimeStr: formatTime(new Date(item.orderBase.orderTime))[0],
            newOrderCode: item.newOrderCode,
            orderId: item.orderId,
            isChecked: item.isChecked
          }
        })
        this.page.total = res.count
        if(params.page==1){
          this.initOrderAmount()
        }
      })
    },
    updateSatus(row){
      this.$Modal.confirm({
        title: '修改状态',
        content: !row.isChecked
          ? '<p>是否确定移入订单</p>'
          : '<p>是否确定移回订单</p>',
        onOk: () => {
          row.isChecked = !row.isChecked
          let data = {
            "IsChecked": row.isChecked,
            "Items": [String(row.orderId)]
          }
          this.$api.order.updateOrderShowStatus(data).then((res) => {
            console.log(res)
            this.getOrderList(this.formValidate)
          })
        },
        onCancel: () => {
          // this.$Message.info('点击了取消');
        },
      })
    },
    // 合计移入金额
    initOrderAmount(){
      let { start, end } = this.formValidate
      let params = {
        start,
        end
      }
      this.$api.order.getOrderGroup(params).then((res) => {
        let amount = 0
        Object.keys(res).map((key) => {
          amount += res[key]
        })
        this.orderAmount = (amount/100).toFixed(2)
        // this.page.total = res.count
      })
    },
    // 订单详情
    showOrderDetail(row){
      this.rowData = row
      let { orderId } = row
      this.showModal3 = true
      this.foodData = []
      this.$api.order.getOrderDetail({ orderId }).then(res=>{
        this.foodData = res
      })
    }
  },
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 28px;
  font-size: 24px;
  .md-download-btn {
    font-size: 16px;
  }
}
.tab {
  background: #ffffff;
}
.table {
  background: #ffffff;
  padding: 0 30px;
  position: relative;
  height: calc(100vh - 155px);
  /deep/.i-page.fixed {
    position: absolute;
    bottom: -40px;
  }
  .btn {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }
}
// .btn-save {
//   width: 80px;
//   height: 30px;
//   background: #85c989;
//   box-shadow: 0px 2px 2px 0px #448247, 0px 0px 1px 0px #b4ffb9;
//   font-size: 14px;
//   font-weight: bold;
//   color: #ffffff;
//   text-shadow: 0px 1px 1px #163124, 0px 3px 5px #bcffca;
//   margin-right: 11px;
//   border: none;
// }

.amount-tips {
  display: flex;
  align-items: center;
  height: 45px;
  font-weight: bold;
  font-size: 18px;
}
</style>
