import { render, staticRenderFns } from "./order-details.vue?vue&type=template&id=04fbd5ab&scoped=true"
import script from "./order-details.vue?vue&type=script&lang=js"
export * from "./order-details.vue?vue&type=script&lang=js"
import style0 from "./order-details.vue?vue&type=style&index=0&id=04fbd5ab&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fbd5ab",
  null
  
)

export default component.exports